

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PoppinsRegular";
}
@font-face {
  font-family: "PoppinsBold";
  src: url("components/fonts/Poppins-Bold.woff2") format("woff2"),
    url("components/fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PoppinsLight";
  src: url("components/fonts/Poppins-Light.woff2") format("woff2"),
    url("components/fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("components/fonts/Poppins-Regular.woff2") format("woff2"),
    url("components/fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
:root {
  --primary-color: #ff931E;
  --secondary-color: #000000;
  --white-color: #ffffff;
  --black-color: #000000;
  --green-color: #158e5b;
}
h3 {
  font-size: 25px;
}
p {
  font-size: 20px;
}
header {
  width: 100%;
  background: var(--primary-color);
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 4px var(--secondary-color);
}
header .d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .d-flex img {
  width: 120px;
}
.contenido {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}
.logo-principal img {
  width: 300px;
}
.qr_user {
  padding-top: 60px;
  padding-bottom: 50px;
}
#svg-qr {
  width: 450px;
  position: relative;
}
#svg-qr .cls-1,
#svg-qr .cls-2,
#svg-qr .cls-3,
#svg-qr .cls-4 {
  fill: none;
}
#svg-qr .cls-1,
#svg-qr .cls-5 {
  stroke-width: 0px;
}
#svg-qr .cls-2,
#svg-qr .cls-3 {
  stroke-width: 5px;
}
#svg-qr .cls-2,
#svg-qr .cls-4 {
  stroke: var(--primary-color);
}
#svg-qr .cls-3 {
  stroke: var(--secondary-color);
}
#svg-qr .cls-4 {
  stroke-miterlimit: 4;
  stroke-width: 5px;
}
#svg-qr .cls-5 {
  fill: var(--primary-color);
}
.img-qr svg {
  width: 263px;
  height: 263px;
  position: absolute;
  margin-top: -364px;
  margin-left: -132px;
}
.user-date h3 {
  color: var(--primary-color);
  font-family: "PoppinsBold";
  text-transform: uppercase;
}
.user-date .folio {
  font-family: "PoppinsLight";
  font-size: 16px;
  text-transform: uppercase;
  color: var(--black-color);
}
.user-date p {
  font-family: "PoppinsLight";
  color: var(--black-color);
}
.user-date p b {
  color: var(--primary-color);
  font-family: "PoppinsBold";
}
.primary-btn {
  background: var(--primary-color);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  font-size: 18px;
  color: var(--white-color);
  text-decoration: none;
  font-family: "PoppinsRegular";
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.primary-btn img {
  height: 20px;
  margin-right: 9px;
  vertical-align: middle;
}
.primary-btn:hover {
  background: var(--secondary-color);
}
.green-btn {
  background: var(--green-color);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  font-size: 18px;
  color: var(--white-color);
  text-decoration: none;
  font-family: "PoppinsRegular";
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.green-btn img {
  height: 20px;
  margin-right: 9px;
  vertical-align: middle;
}
.green-btn:hover {
  background: var(--secondary-color);
}
.qr-valido-text{
  font-size: 25px;
  color: var(--primary-color);
  font-family: "PoppinsLight";
}
.line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line hr {
  border: solid 2px var(--primary-color);
  width: 600px;
}
.dinamica {
  width: 100%;
  display: flex;
  justify-content: center;
}
.dinamica .text-dinamica {
  width: 600px;
}
.dinamica .text-dinamica h3 {
  font-family: "PoppinsBold";
  color: var(--secondary-color);
}
.dinamica .text-dinamica p {
  font-family: "PoppinsLight";
}
footer {
  background: var(--primary-color);
  border-top: solid 4px var(--secondary-color);
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
footer img {
  width: 150px;
}
footer .legales {
  margin-top: 10px;
  color: var(--white-color);
}
footer .legales a {
  color: var(--white-color);
  font-size: 15px;
}
footer .legales a:hover {
  color: var(--secondary-color);
}
.modal-qr{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}
.modal-qr:target {
  opacity: 1;
  pointer-events: auto;
}
.modalbox {
  width: 600px;
  position: relative;
  padding-bottom: 40px;
  background: var(--white-color);
  border-radius: 10px;
  transition: all 500ms ease-in;
}
.movedown {
  margin: 0 auto;
}
.modal-qr:target .movedown {
  margin: 10% auto;
}
.header-modal{
  background: var(--primary-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
  border-bottom: solid 4px var(--secondary-color);
}
.header-modal .d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-modal .d-flex img {
  width: 80px;
}
.modal-content{
  padding: 20px 20px 40px 20px;
}
.modal-content ul{
  margin-left: 30px;
  margin-top: 10px;
}
.modal-content ul li{
  color: var(--primary-color);
}
.btn-close {
  background: var(--primary-color);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  font-size: 18px;
  color: var(--white-color);
  text-decoration: none;
  font-family: "PoppinsLight";
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.btn-close:hover {
  background: var(--secondary-color);
}
.btns{
  text-align: center;
}
.title-edit svg{ 
  width: 16px;
  margin-right: 5px;
  fill: var(--primary-color);
}
.title-edit{
  color: var(--primary-color);
  font-family: 'PoppinsBold';
}
.form-edit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-edit form{
  width: 600px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.mb label{
  display: block;
  font-size: 16px;
  font-family: 'PoppinsRegular';
  text-align: left;
  margin-bottom: 10px;
  color: var(--primary-color);
}
.mb input{
  display: block;
  font-size: 19px;
  font-family: 'PoppinsRegular';
  text-align: left;
  padding-left: 15px;
  width: 100%;
  border-radius: 10px;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--black-color);
  border: solid 1px var(--primary-color);
}
.mb input:focus,
.mb input:hover {
  border: solid 1px var(--secondary-color);
  outline: 0;
}
.mb{
  margin-bottom: 25px;
}
@media (max-width: 600px) {
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
  .qr-valido-text{
    font-size: 20px;
  }
  header {
    padding-left: 30px;
    padding-right: 30px;
  }
  header .d-flex img {
    width: 90px;
  }
  .logo-principal img {
    width: 200px;
  }
  #svg-qr {
    width: 320px;
  }
  .img-qr svg {
    width: 187px;
    height: 187px;
    margin-top: -261px;
    margin-left: -93.3px;
  }
  .user-date .folio {
    font-size: 14px;
  }
  .primary-btn {
    font-size: 14px;
  }
  .primary-btn img {
    height: 15px;
  }
  .green-btn {
    font-size: 14px;
  }
  .btn-close{
    font-size: 14px;
  }
  .green-btn img {
    height: 15px;
  }
  .line hr {
    width: 80%;
  }
  .dinamica .text-dinamica {
    width: 80%;
  }
  .modal-qr:target .movedown {
    margin: 30% auto;
  }
  .modalbox {
    width: 90%;
  }
  .form-edit form{
    width: 90%; 
  }
}
@media (max-width: 320px) {
  #svg-qr {
    width: 275px;
  }
  .img-qr img {
    width: 162px;
    height: 162px;
    margin-top: -226px;
    margin-left: -81.3px;
  }
}

.Noencontrado{
  font-size: 50px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PoppinsRegular";
}
@font-face {
  font-family: "PoppinsBold";
  src: url("components/fonts/Poppins-Bold.woff2") format("woff2"),
    url("components/fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PoppinsLight";
  src: url("components/fonts/Poppins-Light.woff2") format("woff2"),
    url("components/fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("components/fonts/Poppins-Regular.woff2") format("woff2"),
    url("components/fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
:root {
  --primary-color: #FF931E;
  --secondary-color: #3e3e3e;
  --white-color: #ffffff;
  --black-color: #000000;
  --green-color: #158e5b;
}
html{
  height: 100%;
}
body{
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
h3 {
  font-size: 25px;
}
p {
  font-size: 20px;
}
header {
  width: 100%;
  background: var(--primary-color);
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 4px var(--secondary-color);
}
header .d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .d-flex img {
  width: 120px;
}
.contenido {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}
.logo-principal img {
  width: 300px;
}
.qr_user {
  padding-top: 60px;
  padding-bottom: 50px;
}
#svg-qr {
  width: 450px;
  position: relative;
}
#svg-qr .cls-1,
#svg-qr .cls-2,
#svg-qr .cls-3,
#svg-qr .cls-4 {
  fill: none;
}
#svg-qr .cls-1,
#svg-qr .cls-5 {
  stroke-width: 0px;
}
#svg-qr .cls-2,
#svg-qr .cls-3 {
  stroke-width: 5px;
}
#svg-qr .cls-2,
#svg-qr .cls-4 {
  stroke: var(--primary-color);
}
#svg-qr .cls-3 {
  stroke: var(--secondary-color);
}
#svg-qr .cls-4 {
  stroke-miterlimit: 4;
  stroke-width: 5px;
}
#svg-qr .cls-5 {
  fill: var(--primary-color);
}
.img-qr img {
  width: 263px;
  height: 263px;
  position: absolute;
  margin-top: -364px;
  margin-left: -132px;
}
.user-date h3 {
  color: var(--primary-color);
  font-family: "PoppinsBold";
  text-transform: uppercase;
}
.user-date .folio {
  font-family: "PoppinsLight";
  font-size: 16px;
  text-transform: uppercase;
  color: var(--black-color);
}
.user-date p {
  font-family: "PoppinsLight";
  color: var(--black-color);
}
.user-date p b {
  color: var(--primary-color);
  font-family: "PoppinsBold";
}
.primary-btn {
  background: var(--primary-color);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  font-size: 18px;
  color: var(--white-color);
  text-decoration: none;
  font-family: "PoppinsRegular";
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.primary-btn img {
  height: 20px;
  margin-right: 9px;
  vertical-align: middle;
}
.primary-btn:hover {
  background: var(--secondary-color);
}
.green-btn {
  background: var(--green-color);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  font-size: 18px;
  color: var(--white-color);
  text-decoration: none;
  font-family: "PoppinsRegular";
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.green-btn img {
  height: 20px;
  margin-right: 9px;
  vertical-align: middle;
}
.green-btn:hover {
  background: var(--secondary-color);
}
.qr-valido-text{
  font-size: 25px;
  color: var(--primary-color);
  font-family: "PoppinsLight";
}
.line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line hr {
  border: solid 2px var(--primary-color);
  width: 600px;
}
.dinamica {
  width: 100%;
  display: flex;
  justify-content: center;
}
.dinamica .text-dinamica {
  width: 600px;
}
.dinamica .text-dinamica h3 {
  font-family: "PoppinsBold";
  color: var(--secondary-color);
}
.dinamica .text-dinamica p {
  font-family: "PoppinsLight";
}
footer {
  background: var(--primary-color);
  border-top: solid 4px var(--secondary-color);
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  margin-top: auto;
}
footer img {
  width: 150px;
}
footer .legales {
  margin-top: 10px;
  color: var(--white-color);
}
footer .legales a {
  color: var(--white-color);
  font-size: 15px;
}
footer .legales a:hover {
  color: var(--secondary-color);
}
.modal-qr{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}
.modal-qr:target {
  opacity: 1;
  pointer-events: auto;
}
.modalbox {
  width: 600px;
  position: relative;
  padding-bottom: 40px;
  background: var(--white-color);
  border-radius: 10px;
  transition: all 500ms ease-in;
}
.movedown {
  margin: 0 auto;
}
.modal-qr:target .movedown {
  margin: 10% auto;
}
.header-modal{
  background: var(--primary-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
  border-bottom: solid 4px var(--secondary-color);
}
.header-modal .d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-modal .d-flex img {
  width: 80px;
}
.modal-content{
  padding: 20px 20px 40px 20px;
}
.modal-content ul{
  margin-left: 30px;
  margin-top: 10px;
}
.modal-content ul li{
  color: var(--primary-color);
}
.btn-close {
  background: var(--primary-color);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  font-size: 18px;
  color: var(--white-color);
  text-decoration: none;
  font-family: "PoppinsLight";
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.btn-close:hover {
  background: var(--secondary-color);
}
.btns{
  text-align: center;
}
.title-edit svg{ 
  width: 16px;
  margin-right: 5px;
  fill: var(--primary-color);
}
.title-edit{
  color: var(--primary-color);
  font-family: 'PoppinsBold';
}
.form-edit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-edit form{
  width: 600px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.mb label{
  display: block;
  font-size: 16px;
  font-family: 'PoppinsRegular';
  text-align: left;
  margin-bottom: 10px;
  color: var(--primary-color);
}
.mb input{
  display: block;
  font-size: 19px;
  font-family: 'PoppinsRegular';
  text-align: left;
  padding-left: 15px;
  width: 100%;
  border-radius: 10px;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--black-color);
  border: solid 1px var(--primary-color);
}
.mb input:focus,
.mb input:hover {
  border: solid 1px var(--secondary-color);
  outline: 0;
}
.mb{
  margin-bottom: 25px;
}
.referidos{
  width: 100%;
  display: flex;
  justify-content: center;
}
.content-referidos{
  width: 600px;
}
.card-referido{
  width: 100%;
  border: solid 2px var(--primary-color);
  border-radius: 5px;
  margin-bottom: 10px;
}
.content-referidos h4{
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-referidos h4 img{
  margin-left: 10px;
  width: 18px;
}
.card-referido .data-referido{
  display: flex;
}
.card-referido .data-referido .titulo-referido{
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 10px;
  width: 30%;
  border: solid 2px var(--white-color);
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-referido .data-referido .texto-referido{
  padding: 10px;
  width: 70%;
  text-align: start;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
 
}
.texto-referido img{
  width: 14px;
  margin-left: 6px;
}
@media (max-width: 700px){
  .content-referidos{
    width: 80%;
  }
}
@media (max-width: 600px) {
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
  .qr-valido-text{
    font-size: 20px;
  }
  header {
    padding-left: 30px;
    padding-right: 30px;
  }
  header .d-flex img {
    width: 90px;
  }
  .logo-principal img {
    width: 200px;
  }
  #svg-qr {
    width: 320px;
  }
  .img-qr img {
    width: 187px;
    height: 187px;
    margin-top: -261px;
    margin-left: -93.3px;
  }
  .user-date .folio {
    font-size: 14px;
  }
  .primary-btn {
    font-size: 14px;
  }
  .primary-btn img {
    height: 15px;
  }
  .green-btn {
    font-size: 14px;
  }
  .btn-close{
    font-size: 14px;
  }
  .green-btn img {
    height: 15px;
  }
  .line hr {
    width: 80%;
  }
  .dinamica .text-dinamica {
    width: 80%;
  }
  .modal-qr:target .movedown {
    margin: 30% auto;
  }
  .modalbox {
    width: 90%;
  }
  .form-edit form{
    width: 90%; 
  }
}
@media (max-width: 320px) {
  #svg-qr {
    width: 275px;
  }
  .img-qr img {
    width: 162px;
    height: 162px;
    margin-top: -226px;
    margin-left: -81.3px;
  }
}